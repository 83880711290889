
declare global {
    interface Window {
        fbAsyncInit: any
    }
}
declare let FB: any;

export function setupFacebookPageAndMessager(facebookAppId : string) {
    window.fbAsyncInit = () => {
        FB.init({
            appId: facebookAppId,
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v7.0'
        });
        // TODO now: 
        // Ŀǰÿһ�δ򿪶��ᴥ��, ��Ӱ�� tracking, ֮�� fixed
        FB.Event.subscribe('customerchat.dialogShow', () => {
            // tracking
            gtag('event', 'live_chat', {
                event_category: 'engagement'
            });
            fbq('track', 'Contact');
        });
    };

    let script = document.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';  
    script.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
    script.crossOrigin = 'anonymous';  
    document.getElementsByTagName('body')[0].appendChild(script);
}






