import './home.scss';
import { setupLayout } from '../Shared/layout';
import * as $ from 'jquery';
import 'owl.carousel/dist/owl.carousel';
import '@fancyapps/fancybox';
import { appConfig } from '../../src/appConfig';
import { setupGoogleMap } from '../../src/module/google-map';

setupLayout();

setupGoogleMap({
    apiKey: appConfig.googleMapApiKey,
    languageIsoCode: (document.getElementById('inputHiddenForPageLanguageIsoCode') as HTMLInputElement).value,
    styles: appConfig.googleMapStyles
});

$(document).ready(function () {
    $('.owl-homeCarousel').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
            0: {
                items: 1
            },
            426: {
                items: 1
            },
            1025: {
                items: 1
            }
        }
    });

    $('.owl-homeProgramme').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        smartSpeed: 1500,
        responsive: {
            0: {
                items: 1
            },
            426: {
                items: 2
            },
            1025: {
                items: 3
            }
        }
    });

    $('.owl-homeParentTestimonial').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            //426: {
            //    items: 1
            //},
            //1025: {
            //    items: 1
            //}
        }
    });

    $('.owl-homeAward').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        autoplay: false,
        autoplayTimeout: 5000,
        responsive: {
            0: {
                items: 1
            },
            426: {
                items: 2
            },
            1025: {
                items: 2
            }
        }
    });

    $('.owl-homeBranch').owlCarousel({
        loop: false,
        margin: 5,
        nav: false,
        dots: true,
        autoplay: false,
        autoplayTimeout: 5000,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1
            },
            426: {
                items: 2
            },
            1025: {
                items: 3
            }
        }
    });

    $('.owl-newsFeed').owlCarousel({
        loop: false,
        margin: 16,
        nav: false,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            //426: {
            //    items: 2
            //},
            // 1025: {
            //     items: 2
            // }
        }
    });

    $('.owl-homeMedia').owlCarousel({
        loop: true,
        margin: 80,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 3000,
        smartSpeed: 1500,
        responsive: {
            0: {
                items: 2
            },
            426: {
                items: 4
            },
            769: {
                items: 5
            },
            1025: {
                items: 5
            }
        }
    });
});

// track


document.querySelector<HTMLLinkElement>('#js-introductionVideoLink').addEventListener('click', () => {
    gtag('event', 'play_video', {
        send_to: appConfig.googleAnalyticsId,
        event_category: 'engagement',
        event_label: 'Introduction'
    });

    // note 解忧: ga4 video 会自动 track 的, 如果确定它会自动 track 这里就可以洗掉了
    gtag('event', 'play_video', {
        send_to: appConfig.googleAnalytics4Id,
        name: 'Introduction'
    });

    // TODO now
    // note 解忧: fb 就不 track 了, 感觉没有合适的 event 也不太重要
});

