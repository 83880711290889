export function scrollToTop() {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
}

export function slideUp(element: HTMLElement) {
    element.style.overflow = 'hidden';

    const elemAnimation = element.animate([
        { height: `${element.offsetHeight}px` },
        { height: 0 }
    ], {
        duration: 500,
        easing: 'ease'
    });

    elemAnimation.addEventListener('finish', () => {
        element.style.display = 'none';
        element.style.removeProperty('overflow');
    });
}

export function slideDown(element: HTMLElement) {
    element.style.overflow = 'hidden';
    element.style.display = 'block';

    const elemAnimation = element.animate([
        { height: 0 },
        { height: `${element.offsetHeight}px` }
    ], {
        duration: 500,
        easing: 'ease'
    });

    elemAnimation.addEventListener('finish', () => {
        element.style.removeProperty('overflow');
    });
}

export function slideToggle(element: HTMLElement) {
    window.getComputedStyle(element).display === 'none' ? slideDown(element) : slideUp(element);
}

