import '@fancyapps/fancybox';
import { appConfig } from '../../../../src/appConfig';

export function setupSocialMedia() {
    document.querySelector<HTMLLinkElement>('#js-whatsAppLink').addEventListener('click', () => {
        gtag('event', 'whatsapp', {
            send_to: appConfig.googleAnalyticsId,
            event_category: 'engagement'
        });
        gtag('event', 'generate_lead', {
            send_to: appConfig.googleAnalytics4Id
        });
        gtag('event', 'whatsapp', {
            send_to: appConfig.googleAnalytics4Id
        });
        fbq('track', 'Contact');
    });
}


