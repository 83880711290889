const mapIcon = require('../image/googleMapMarkerIcon.png');

declare global {
    interface Window {
        initMap: () => void
    }
    let google: any
}

export function setupGoogleMap(config: {
    apiKey: string,
    languageIsoCode: string,
    //locations: {
    //    position: {
    //        lat: number,
    //        lng: number
    //    },
    //    url: string
    //}[],
    //zoom: number,
    //center: {
    //    lat: number,
    //    lng: number
    //},
    styles: any[]
}) {
    let script = document.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.apiKey}&callback=initMap&language=${config.languageIsoCode}`;
    document.getElementsByTagName('body')[0].appendChild(script);

    window.initMap = () => {
        document.querySelectorAll<HTMLElement>('.sGoogleMap').forEach(mapElement => {
            // note ����: ΪʲôҪ��ǰ�ó� element ����Ϊ google map ��ִ��ʱ�Ḳ�� child element
            const markerElements = Array.from(mapElement.querySelectorAll<HTMLElement>('.sGoogleMapMarker')); 

            const map = new google.maps.Map(mapElement, {
                zoom: +mapElement.dataset.zoom,
                center: {
                    lat: +mapElement.dataset.centerLat,
                    lng: +mapElement.dataset.centerLng
                },
                scrollwheel: false,
                styles: config.styles
            });

            markerElements.forEach(markerElement => {
                let marker = new google.maps.Marker({
                    position: {
                        lat: +markerElement.dataset.positionLat,
                        lng: +markerElement.dataset.positionLng
                    },
                    map: map,
                    icon: mapIcon.default,
                    url: markerElement.dataset.url
                });

                marker.addListener('click', () => {
                    window.open(marker.url, '_blank');
                });
            });
        });
    }
}
