//import { setupGA } from '../../src/module/google-analytics';
import { appConfig } from '../../src/appConfig';
import { setupFacebookPageAndMessager } from '../../src/module/facebook-page-and-messager';
//import { setupPixel } from '../../src/module/facebook-pixel';
import { setTimeout } from 'timers';
import { slideToggle, slideDown } from '../../src/module/dom';
import './Component/SocialMedia/social-media';
//import { setupSessionRecord } from '../../src/module/session-record';
import { setupSocialMedia } from './Component/SocialMedia/social-media';

declare global {
    let fbq: (...args: any[]) => void
}

export function setupLayout() {

    setupSocialMedia();

    //setupGA(appConfig.googleAnalyticsId, {
    //    Id: appConfig.googleAdsId,
    //    convertor: {
    //        'phoneLink': 'ffOKCPeL7tIBEKWb9voC',
    //        'whatsAppLink': '1n0yCMyN7tIBEKWb9voC',
    //        'mapLink': 'Ss2cCMzojtMBEKWb9voC',
    //        'emailLink': 'UaQfCM3w_tIBEKWb9voC',
    //        'liveChat': 'LBmQCMvqjtMBEKWb9voC',
    //        'careerOpportunityApplicationForm': 'W9csCNbn_tIBEKWb9voC',
    //        'generalEnquiry': 't1rSCJGHj9MBEKWb9voC', 
    //        'courseEnquiry': 'KeGoCO2l7tIBEKWb9voC',
    //        'franchiseEnquiry': 'n2InCLe4htQBEKWb9voC',
    //        'campEnquiry': 'JbyxCPHJ0tQBEKWb9voC'
    //    }
    //});
    //setupPixel(appConfig.facebookPixelId);

    // delay facebook page due to google speed score 
    setTimeout(() => {
        setupFacebookPageAndMessager(appConfig.facebookAppId);
    }, 2000);

    if (location.host.startsWith('192.168') || location.host.startsWith('localhost')) {
        // skip
    }
    else {
        // setupSessionRecord(appConfig.sessionRecordId);
    }

    document.addEventListener("DOMContentLoaded", () => {
        document.querySelectorAll<HTMLElement>('.parentNav').forEach(elem => {
            if (window.innerWidth <= 1024) {
                if (elem.classList.contains('active')) {
                    slideDown(elem.nextElementSibling as HTMLElement)
                }
            }
            elem.addEventListener('click', () => {
                if (window.innerWidth <= 1024) {
                    slideToggle(elem.nextElementSibling as HTMLElement);
                }
            });
        });
    });

    document.getElementById('js-menuButton').addEventListener('click', () => {
        slideToggle(document.getElementById('js-navList'));
    });

    document.querySelectorAll<HTMLLinkElement>('#js-headerPhoneLink, #js-footerPhoneLink').forEach(el => {
        el.addEventListener('click', () => {
            gtag('event', 'call', {
                send_to: appConfig.googleAnalyticsId,
                event_category: 'engagement',
                event_label: 'Taman Molek'
            });
            gtag('event', 'generate_lead', {
                send_to: appConfig.googleAnalytics4Id
            });
            gtag('event', 'call', {
                send_to: appConfig.googleAnalytics4Id,
                branch: 'Taman Molek'
            });
            fbq('track', 'Contact');
        });
    });

    document.querySelectorAll<HTMLLinkElement>('#js-headerEmailLink, #js-footerEmailLink').forEach(el => {
        el.addEventListener('click', () => {
            gtag('event', 'email', {
                send_to: appConfig.googleAnalyticsId,
                event_category: 'engagement'
            });
            gtag('event', 'generate_lead', {
                send_to: appConfig.googleAnalytics4Id
            });
            gtag('event', 'email', {
                send_to: appConfig.googleAnalytics4Id
            });
            fbq('track', 'Contact');
        });
    });
    document.querySelector<HTMLLinkElement>('#js-footerLocationLink').addEventListener('click', () => {
        gtag('event', 'find_location', {
            send_to: appConfig.googleAnalyticsId,
            event_category: 'engagement',
            event_label: 'Taman Molek'
        });
        gtag('event', 'generate_lead', {
            send_to: appConfig.googleAnalytics4Id
        });
        gtag('event', 'find_location', {
            send_to: appConfig.googleAnalytics4Id,
            branch: 'Taman Molek'
        });
        fbq('track', 'FindLocation');
    });

    
}










