export const appConfig: {
    facebookAppId: string;
    facebookPixelId: string;
    googleAnalyticsId: string;
    googleAnalytics4Id: string;
    sessionRecordId: string;
    googleAdsId: string;
    googleMapApiKey: string;
    googleMapStyles: any[];
    addThisId: string;
} = {
    ...JSON.parse((document.getElementById('inputHiddenForAppConfig') as HTMLInputElement).value)
}

